<template>
  <div v-if="isLoading">
    <b-spinner small type="grow" class="text-primary mx-auto mt-3 d-block" />
  </div>
  <div v-else-if="hasErrors">
    <div class="mt-3 text-danger text-center">
      {{ $t('error-message.load-failed') }}
    </div>
  </div>

  <section v-else class="detail">
    <unshare-item-modal
      v-if="mediaItem"
      v-model="isUnshareModalVisible"
      :model-type="mediaType"
      @remove-item="deleteItem(mediaItem.key)"
      @modal-invisible="isUnshareModalVisible = false"
    />
    <feather-icon
      v-if="canDelete"
      icon="Trash2Icon"
      size="20"
      class="text-primary trash-icon"
      role="button"
      @click="isUnshareModalVisible = true"
    />
    <div v-if="mediaItem" class="mr-0 mr-lg-2 mr-xl-4">
      <feather-icon
        v-b-tooltip.hover.bottom
        icon="Share2Icon"
        :title="$t('share.tooltip')"
        size="20"
        class="p-0 text-primary share-icon"
        @click="isShareModalVisible = true"
      />
      <b-dropdown
        v-if="canPostMedia"
        id="dropdown-dropleft"
        variant="link"
        right
        class="text-primary edit-icon"
        no-caret
      >
        <template #button-content>
          <feather-icon
            icon="Edit2Icon"
            size="20"
          />
        </template>
        <b-dropdown-text class="bg-light">
          <p class="user-name font-weight-bolder mb-0">
            {{ $t("edit-dropdown.title") }}
          </p>
        </b-dropdown-text>
        <b-dropdown-item @click="handleEditMedia">
          {{ $t("edit-dropdown.details") }}
        </b-dropdown-item>
        <b-dropdown-item
          @click="isClassifiersModalVisible = true"
        >
          {{ $t("members.edit.classifiers.name") }}
        </b-dropdown-item>
        <b-dropdown-item
          @click="isCustomFieldsModalVisible = true"
        >
          {{ $t("members.edit.custom-fields.label") }}
        </b-dropdown-item>
      </b-dropdown>
    </div>

    <b-row v-if="mediaItem" class="mb-1 px-lg-2 px-xl-4 justify-content-md-center">
      <b-col 
        cols="12" 
        :md="isStorage ? '12' : '7'"
      >
        <!-- Media is link -->
        <b-card 
          v-if="mediaItem.isLink && mediaItem.isExternal"
          class="mediaItem externalLink"
          :class="mediaItem.thumbnailURL ? 'high-card' : ''"
          :style="thumbnailImageStyle(mediaItem.thumbnailURL)"
        >
          <b-button
            variant="primary"
            class="mt-2 mb-2"
            target="_blank"
            @click="takeMeToExternalLink"
          >
            {{ $t('media.to-external-file') }}
            <feather-icon icon="ExternalLinkIcon" />
          </b-button>
        </b-card>

        <!-- Media preview -->
        <media-renderer
          v-else
          class="mediaItem"
          :media="mediaItem"
          :media-type="mediaType"
          :title="title"
          @last="onLast"
        />

        <!-- Social reactions -->
        <social-buttons
          :comments-count="mediaItem.commentCount"
          :liked-by-member="mediaItem.likedByMember"
          :total-likes="mediaItem.totalLikes"
          :is-media="true"
          :media="mediaItem"
          class="mx-1 mt-1"
          @change-like="toggleLike"
          @comment="handleCommentAction"
        />
        <!-- Media info -->
        <b-card class="mt-1">
          <div class="d-flex justify-content-between">
            <!-- Media element title -->
            <div class="d-flex align-items-center">
              <h4 class="title h2">
                {{ title }}
              </h4>
            </div>
          </div>

          <div class="d-flex align-items-center actions-buttons border-top">
            <!-- Left -->
            <div class="buttons-left">
              <div v-if="mediaItem.createdBy && canShowCollaborator" class="w-100 pt-1">
                <b-link :to="getMemberLocation(mediaItem.createdBy)" class="d-flex align-items-center">
                  <user-avatar
                    :user="mediaItem.createdBy"
                    size="34"
                  />
                  <div class="ml-1">
                    <p class="font-weight-extrabold mb-0 text-dark">
                      {{ mediaItem.createdBy.name }} {{ mediaItem.createdBy.surname }}
                    </p>
                  </div>
                </b-link>
              </div>
              <div v-else>
                <div
                  class="d-flex align-items-center mt-50"
                >
                  <collective-logo :logo="currentSpaceLogo" />
                  <p class="font-weight-bolder mb-0 ml-1">
                    {{ currentSpaceName }}
                  </p>
                </div>
              </div>
            </div>
            <!-- Right -->
            <div class="buttons-right">
              <!-- Complete -->
              <b-button
                v-if="isCompletable"
                variant="outline-primary"
                class="ml-1"
                :class="{ 'item-completed': completedByMember }"
                @click="onCompleteBtn"
              >
                <feather-icon icon="CheckCircleIcon" size="14" />
                {{ completedByMember ? $t('social.completed') : $t('social.complete') }}
              </b-button>
              <!-- Collaboration -->
              <b-button
                v-if="isCollaborativeDocument"
                variant="outline-primary"
                :to="collaborativeLocation"
              >
                {{ $t('media.edit-collaboratively-action') }}
              </b-button>
              <!-- Download -->
              <b-button
                v-if="mediaItem.downloadMediaURL && !isCollaborativeDocument"
                variant="outline-primary"
                class="ml-1"
                :href="mediaItem.downloadMediaURL"
                target="_blank"
              >
                {{ $t('media.download') }}
                <feather-icon icon="DownloadIcon" size="14" />
              </b-button>
            </div>
          </div>
        </b-card>

        <!-- Card with more information -->
        <b-card class="mt-1">
          <small class="d-block mb-2">
            {{ date }}
          </small>

          <!-- <content-with-anchors :content="description" class="mt-1" /> -->
          <!-- eslint-disable-next-line vue/no-v-html -->
          <span class="mt-1" v-html="description" />

          <!-- Classifiers -->
          <template v-if="classifiers && classifiers.length > 0">
            <hr>
            <div
              v-for="(value, name) in sortedClassifiers"
              :key="value.key"
            >
              <div class="w-100">
                {{ name }}:
              </div>
              <span v-for="(v, index) in value" :key="index">
                <b-badge
                  class="mb-50 mr-50"
                  :style="classifierStyle(v)"
                >
                  {{ typeof v.name === 'object' ?
                    translateTranslationTable(
                      $store.state.locale.currentLocale,
                      v.name
                    )
                    : v.name
                  }}
                </b-badge>
              </span>
            </div>
          </template>

          <!-- Custom fields -->
          <template v-if="mediaItem.custom && mediaItem.custom.length > 0">
            <div
              v-for="(field, index) in mediaItem.custom"
              :key="index"
              class="mt-1"
            >
              <div
                v-if="
                  field.value ||
                    (typeof field.value !== 'string' && !field.value)
                "
                class="mb-1 d-flex flex-wrap"
              >
                <div class="font-weight-bold mr-2">
                  {{ getName(field) }}:
                </div>
                <div>
                  {{ getValue(field.value) }}
                </div>
              </div>
            </div>
          </template>
        </b-card>

        <!-- Comments -->
        <b-card>
          <div class="d-flex align-items-center">
            <h6 class="title">
              {{ $t('comments.title') }}
            </h6>
          </div>
          <comment-form
            ref="commentForm"
            class="comments-input"
            :source-key="mediaItem.key"
            :morph-type="mediaType"
            item-type="media"
            @created="handleCommentCreation"
          />
          <comments-list
            class="mt-2"
            item-type="media"
            :source-key="mediaItem.key"
            :morph-type="mediaType"
            @delete-comment="deleteComment"
          />
        </b-card>
      </b-col>

      <!-- More Media -->
      <b-col
        v-if="!isStorage"
        cols="12"
        md="5"
        class="pl-md-2"
      >
        <div v-if="itemsDataUnpaginated && itemsDataUnpaginated.length > 0">
          <h4 class="title ml-1">
            {{ $t('media.more-media') }}
          </h4>
          <div v-if="itemsDataUnpaginated && itemsDataUnpaginated.length > 1">
            <div
              v-for="moreMediaItem in itemsDataUnpaginated"
              :key="moreMediaItem.key"
              class="d-flex flex-column align-items-center border-bottom align-items-md-start more-media"
            >
              <!-- <pre>moreMediaItem.key: {{ moreMediaItem.key }}</pre> -->
              <media-row
                v-if="moreMediaItem.key !== itemKey"
                :item="moreMediaItem"
                :media-type="mediaType"
                small
              />
            </div>
          </div>
          <img
            v-else
            :src="placeholder"
            class="d-block mx-auto"
            style="width: 150px"
          >
        </div>
        <!-- Show more media -->
        <b-button
          v-if="itemsDataUnpaginated && itemsDataUnpaginated.length < total"
          variant="outline-primary"
          class="w-100 mt-2 no-outline"
          @click="handleLoadOfNewItems"
        >
          {{ $t('media.show-more') }}
        </b-button>
      </b-col>
    </b-row>
    <media-upload-modal
      v-model="isEditMediaModalVisible"
      :media-type="mediaType"
      :media="mediaItem"
      :edit-media="true"
      @mediaCreated="loadData"
    />
    <share-modal
      v-if="mediaItem"
      v-model="isShareModalVisible"
      :can-share="canShare"
      :item-name="title"
      model-type="media"
      :model-key="mediaItem.key"
      type="media"
      :media-type="mediaType"
    />
    <classifiers-modal
      v-if="mediaItem"
      v-model="isClassifiersModalVisible"
      :item-key="itemKey"
      :morph-type="mediaType"
      :model-type="mediaType"
      :classifiers="classifiers"
      @profileUpdated="fetchClassifiers"
    />
    <!-- sortedCustomFields -->
    <customfields-modal
      v-if="mediaItem"
      v-model="isCustomFieldsModalVisible"
      :item="mediaItem"
      :morph-type="mediaType"
      @profile-updated="loadData"
    />
  </section>
</template>

<script>

import CommentForm from '@/views/apps/comments/components/CommentForm.vue';
import CommentsList from '@/views/apps/comments/components/CommentsList.vue';
import SocialButtons from '@core/components/social/SocialButtons.vue';
import UserAvatar from '@core/components/user/UserAvatar.vue';
import CollectiveLogo from "@core/components/collective-logo/CollectiveLogo.vue";
// import ContentWithAnchors from '@core/components/string-utils/ContentWithAnchors.vue';
// import SafeImg from '@core/components/safe-img/SafeImg.vue';
import ShareModal from '@/@core/components/modal/ShareModal.vue';
import MediaRow from '@/views/apps/media/components/MediaRow.vue';
import MediaRenderer from '@/views/apps/media/components/MediaRenderer';
import MediaUploadModal from '@/views/apps/media/components/modal/MediaUploadModal.vue';
import MediaConfigurationMixin from '@/views/apps/media/mixins/MediaConfigurationMixin';
import { checkPermissions } from '@/@core/utils/roles-utils';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { getImageResource } from '@core/utils/image-utils';
import AvatarPlaceholder from '@/assets/images/placeholders/light/community-avatar.jpeg';
import CommentsPlaceholder from '@/assets/images/placeholders/light/comments.svg';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import ClassifiersModal from '@/@core/components/modal/ClassifiersModal.vue';
import UnshareItemModal from '@/@core/components/modal/UnshareItemModal.vue';
import Placeholder from '@/assets/images/placeholders/light/general-placeholder.svg';
import { ENABLED_APPS_GETTERS } from '@/store/enabled-apps/enabled-apps-store-constants';
import CustomfieldsModal from '@/@core/components/modal/CustomfieldsModal.vue';
import { dateValidator } from '@/@core/utils/validations/validators';
import { giveCompleted } from '@/services/backend/giveCompleted';
import { takeCompleted } from '@/services/backend/takeCompleted';

export default {
  name: 'MediaDetails',
  components: {
    CommentForm,
    CommentsList,
    UserAvatar,
    // ContentWithAnchors,
    // SafeImg,
    ClassifiersModal,
    SocialButtons,
    MediaRow,
    MediaRenderer,
    MediaUploadModal,
    ShareModal,
    UnshareItemModal,
    CustomfieldsModal,
    CollectiveLogo,
  },
  mixins: [MediaConfigurationMixin, ToastNotificationsMixin],
  data() {
    return {
      isLoading: true,
      hasErrors: false,
      currentPage: 0,
      itemUpdated: {},
      isEditMediaModalVisible: false,
      lastLoadedPage: 1,
      isShareModalVisible: false,
      isUnshareModalVisible: false,
      isClassifiersModalVisible: false,
      isCustomFieldsModalVisible: false,
      completedByMember: false,
      //thumbnailImageStyle: '',
    };
  },
  computed: {
    widget() {
      const app = Object.values(
        this.$store.getters[ENABLED_APPS_GETTERS.enabledApps],
      ).filter((item) => {
        if (item.key.includes(this.mediaType)) {
          return item.name;
        }
        return '';
      });
      return app[0];
    },
    canShowCollaborator() {
      return this.widget?.customization?.collaboratorDisplay;
    },
    placeholder() {
      return Placeholder;
    },
    itemKey() {
      return this.$route.params.itemKey;
    },
    isStorage() {
      return this.$route.path.includes('storage');
    },
    canPostMedia() {
      return this.$store.getters.currentCollective.isStaff || checkPermissions(
        'update',
        `${this.mediaType}`,
        this.loggedMemberRoles,
        this.$store.getters.currentCollective,
      );
    },
    canDelete() {
      return this.$store.getters.currentCollective.isStaff || checkPermissions(
        'delete',
        `${this.mediaType}`,
        this.loggedMemberRoles,
        this.$store.getters.currentCollective,
      );
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    itemsDataSingle() {
      return this.$store.getters.media?.[this.mediaType+'-single'];
    },
    itemsData() {
      return this.$store.getters.media?.[this.mediaType];
    },
    itemsDataSingleUnpaginated() {
      if (this.itemsDataSingle) {
      // console.log('this.itemsDataSingle', this.itemsDataSingle);
        return this.itemsDataSingle?.unpaginated;
      }
      return [];
    },
    itemsDataUnpaginated() {
      if (this.itemsData) {
        return this.itemsData?.unpaginated;
      }
      return [];
    },
    itemsDataMeta() {
      if (this.itemsData) {
        return this.itemsData?.meta;
      }
      return [];
    },
    mediaItem() {
      // Storage
      if (this.isStorage && this.itemKey) {
        return this.$store.getters.item('storage', this.itemKey);
      }
      // console.log('this.itemsData', this.itemsData);
      // console.log('this.itemsDataSingle', this.itemsDataSingle);
      // Not storage, media app
      // if (this.itemKey && this.mediaType && this.itemsData) {
      //   return this.$store.getters.fragmentedItem('media', this.itemKey, this.mediaType);
      // }
      if (this.itemKey && this.mediaType && this.itemsDataSingle) {
        return this.$store.getters.fragmentedItem('media', this.itemKey, this.mediaType+'-single');
      }
      return {};
    },
    total() {
      return this.itemsDataMeta?.total || 1;
    },
    Organizationplaceholder() {
      return AvatarPlaceholder;
    },
    Commentsplaceholder() {
      return CommentsPlaceholder;
    },
    date() {
      return this.$moment(this.mediaItem.createdAt).fromNow();
    },
    createdByURL() {
      return getImageResource(this.mediaItem.createdBy.avatarURL);
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    currentSpaceLogo() {
      return this.collective?.logoURL;
    },
    currentSpaceName() {
      return translateTranslationTable(this.$store.state.locale.currentLocale, this.collective?.name);
    },
    description() {
      return translateTranslationTable(this.$store.state.locale.currentLocale, this.mediaItem.description) ?? 'No description available.';
      //?? $t('no-description-available')
    },
    title() {
      if (this.mediaItem != null) {
        const translateName = typeof this.mediaItem?.name === 'object'
          ? translateTranslationTable(this.$store.state.locale.currentLocale, this.mediaItem?.name)
          : this.mediaItem?.name;
        return translateName?.replaceAll('_', ' ');
      }
      return null;
    },
    classifiersData() {
      if (this.$store.getters?.classifiers.media) {
        return this.$store.getters.classifiers.media?.unpaginated?.reduce((acc, current) => {
          const x = acc.findIndex((item) => item.key === current.key);
          if (x === -1) {
            return acc.concat([current]);
          }
          acc[x] = current;
          return acc;
        }, []);
      }
      return [];
    },
    classifiers() {
      return this.classifiersData;
    },
    sortedClassifiers() {
      const newObject = {};
      this.classifiers?.forEach((item) => {
        if (!newObject[translateTranslationTable(this.$store.state.locale.currentLocale, item.typeName)]) {
          newObject[translateTranslationTable(this.$store.state.locale.currentLocale, item.typeName)] = [item];
        } else {
          newObject[translateTranslationTable(this.$store.state.locale.currentLocale, item.typeName)].push(item);
        }
      });
      return newObject;
    },
    isCollaborativeDocument() {
      return this.mediaItem.url.includes('.docx')
      || this.mediaItem.url.includes('.doc')
      || this.mediaItem.url.includes('.pptx') || this.mediaItem.url.includes('.xlsx');
    },
    isCompletable() {
      return this.mediaItem.isCompletable;
    },
    collaborativeLocation() {
      return {
        name: 'collaborative-documents',
        params: {
          id: this.mediaItem.key,
          media: this.mediaItem,
          communityId: this.$route.params.communityId,
        },
      };
    },
    canShare() {
      return checkPermissions('share', 'media', this.loggedMemberRoles, this.$store.getters.currentCollective);
    },
    apps() {
      return this.$store.getters.apps;
    },
    app() {
      if (this.apps) {
        const installedApps = Object.values(this.apps.apps);
        return installedApps.find(
          ({ key }) => key === `${this.mediaType}s`,
        );
      }
      return {};
    }
  },
  watch: {
    title() {
      return this.updateBreadcrumbs();
    },
  },
  async created() {
    this.isLoading = true;
    // console.log('Media:', this.media);
    // console.log('mediaItem:', this.mediaItem);
    if(this.media == undefined){
      // console.log('Fetch media item');
      await this.fetchMediaItem();
      await this.fetchData(true);
      if(this.itemKey){
        await this.fetchClassifiers();
      }
    }
    //console.log('mediaItem:', this.mediaItem);
    this.completedByMember = this.mediaItem?.completedByMember;
    this.isLoading = false;
  },
  methods: {
    thumbnailImageStyle(url){
      const style = 'background-image: url("' + this.mediaItem.thumbnailURL + '"); background-position: center; background-size: cover; opacity: 0.8; background-repeat: no-repeat;';
      return url ? decodeURI(style) : '';
    },
    //Fetch a single item
    async fetchMediaItem() {
      // console.log('Fetching single media item...');
      await this.$store.dispatch('getItems', {
          forceAPICall: true,
          itemType: 'media',
          storedKey: this.mediaType+'-single',
          page: this.lastLoadedPage,
          requestConfig: {
            mediaKey: this.itemKey,
            count: 1,
          },
        });
    },
    //Fetch a page of items
    async fetchData(force = false) {
      // console.log('Fetching a page of media items...');
      this.isLoading = true;
      if (this.$route.path.includes('storage')) {
        await this.$store.dispatch('getItems', {
          itemType: 'storage',
          page: this.lastLoadedPage,
          perPage: 1,
          forceAPICall: true,
          requestConfig: {
            mediaKey: this.itemKey,
            literal: true,
          },
        });
      } else {
        await this.$store.dispatch('getItems', {
          forceAPICall: force,
          itemType: 'media',
          storedKey: this.mediaType,
          page: this.lastLoadedPage,
          requestConfig: {
            morphType: this.mediaType,
            count: 10,
            orderByDate: -1,
          },
        });
      }
      this.isLoading = false;

      this.updateBreadcrumbs();
    },
    onLast() {
      this.completedByMember = true;
    },
    async onCompleteBtn() {
      // console.log('onCompleteBtn...: ', this.completedByMember);
      try {
        if (!this.completedByMember) {
          await giveCompleted({
            communityKey: this.collective.key,
            key: this.mediaItem.key,
            morphType: "media",
          });
          this.completedByMember = true;
        } else {
          await takeCompleted({
            communityKey: this.collective.key,
            key: this.mediaItem.key,
            morphType: "media",
          });
          this.completedByMember = false;
        }
        // console.log('onCompleteBtn (completedByMember): ', this.completedByMember);
      } catch (error) {
        // console.log('onCompleteBtn (error): ', error);
        return error;
      }
    },
    getName(field) {
      let name = '';
      if (Array.isArray(this.mediaItem.custom)) {
        this.mediaItem.custom.map((item) => {
          if (field.key === item.key) {
            name = item.customFieldName;
          }
          return '';
        });
        return translateTranslationTable(
          this.$store.state.locale.currentLocale,
          name,
        );
      }
      return name;
    },
    getValue(value) {
      if (dateValidator(value)) {
        return value.split('-').reverse().join('-');
      }
      if (value === true) {
        return this.$t('yes');
      }
      if (value === false) {
        return this.$t('no');
      }
      return value;
    },
    async loadData() {
      await this.fetchData(true);
    },
    deleteComment() {
      --this.mediaItem.commentCount;
    },
    getMemberLocation(user) {
      if (!user.username) {
        return null;
      }
      return {
        name: 'member-details',
        params: { username: user.username, communityId: this.$route.params.communityId },
      };
    },
    async deleteItem(key) {
      const { communityId } = this.$route.params;

      try {
        await this.$store.dispatch('deleteMedia', {
          item: {
            itemType: 'media',
            requestConfig: {
              mediaKey: key,
              mediaType: this.mediaType,
            },
          },
        });
        this.$router.push(`/${communityId}/${this.mediaType}s`);
      } catch (error) {
        return error;
      }
    },
    async toggleLike() {
      await this.$store.dispatch('toggleLike', {
        itemType: 'media',
        morphType: this.mediaType,
        key: this.mediaItem.key,
        storedKey: this.mediaType,
      });
    },
    handleCommentAction() {
      this.$refs.commentForm.focus();
    },
    handleCommentCreation() {
      ++this.mediaItem.commentCount;
    },
    handleEditMedia() {
      this.isEditMediaModalVisible = true;
    },
    updateBreadcrumbs() {
      const mediaTypeNamePlural = this.mediaType === 'file' ? 'storage' : `${this.mediaType}s`;
      const mediaTypeNameSingular = this.mediaType === 'file' ? 'storage' : this.mediaType;
      const breadcrumbs = [
        {
          text: translateTranslationTable(
            this.$store.state.locale.currentLocale,
              this.app?.customizationName || this.app?.app?.customizationName,
          ) || `${mediaTypeNamePlural}.breadcrumb-text.${mediaTypeNamePlural}-list`,
          to: { name: `${mediaTypeNamePlural}` },
        },
        {
          text: this.title ? this.title : `${mediaTypeNamePlural}.breadcrumb-text.${mediaTypeNameSingular}-details`,
          active: true,
        },
      ];

      this.$store.commit('app/SET_BREADCRUMBS', breadcrumbs);
    },
    async handleLoadOfNewItems() {
      if (!this.isLoading && this.itemsDataUnpaginated.length < this.total) {
        this.lastLoadedPage += 1;
        await this.fetchData(true);
      }
    },
    async fetchClassifiers() {
      this.isLoading = true;
      await this.$store.dispatch('getItems', {
        storedKey: 'media',
        itemType: 'classifiers',
        customName: 'classifiers',
        forceAPICall: true,
        page: 1,
        requestConfig: {
          mediaKey: this.itemKey,
        },
      });
      this.isLoading = false;
      this.updateBreadcrumbs();
    },
    classifierStyle(classifier) {
      return {
        color: classifier.fontColor,
        'background-color': classifier.backgroundColor,
      };
    },
    translateTranslationTable,
    takeMeToExternalLink() {
      window.open(
        this.mediaItem.url,
        '_blank' 
      );
    }
  },
};
</script>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-include';
.edit-button {
  @include media-breakpoint-up(md) {
    position: relative;
    top: -55px;
    right: 0;
  }
}
.mediaItem {
  border-radius: 1.2rem;
}
.more-media:hover {
  background-color: rgba(0, 0, 0, 0.02);
}
.detail {
  position: relative;
}
.share-icon {
  position: absolute;
  top: -40px;
  right: 0;
  cursor: pointer;
}
.edit-icon {
  position: absolute;
  right: 10px;
  top: -53px;
  cursor: pointer;
}
.trash-icon {
  position: absolute;
  right: 70px;
  top: -41px;

}

.actions-buttons {
  margin-top: 10px;
  padding-top: 10px;
}
.item-completed {
  color: green !important;
  font-weight: 700;
  background-color: #b4f5b4;
}
.no-outline {
  border: none !important;
}
.buttons-right {
  position: absolute;
  right: 20px;
}
.buttons-left {
  max-width: 50%;
}
.externalLink {
  margin: auto;
  text-align: center;
  min-height: 100px;
}
.high-card {
  height: 200px;
}
</style>
